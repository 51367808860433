import 'what-input'
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import { RowSlider } from '@js/components/rowSlider.ts'
import { ProductSlider } from '@js/components/productSlider.ts'
import { ContentSlider } from '@js/components/contentSlider.ts'
import { SingleSlider } from '@js/components/singleSlider.ts'
import { Slider } from '@js/components/slider.ts'
import { Modal } from './components/modal.ts'
import wdLogin from '@js/components/wdLogin.js'
import wdCart from '@js/components/wdCart.js'
import wdAddToCart from '@js/components/wdAddToCart.js'
import wdForm from '@js/components/wdForm.js'
import { WineFinder } from './winefinder/index.ts'
import replaceTextInDocument from './lib/replaceText.ts'
import { fitText, debounceFitText } from './lib/fitText.ts'

// Video facades
import '@js/components/liteYoutube.ts'
import '@js/components/liteVimeo.ts'

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.plugin(focus)

Alpine.data('wdlogin', wdLogin)
Alpine.data('wdcart', wdCart)
Alpine.data('addtocart', wdAddToCart)
Alpine.data('wdform', wdForm)
Alpine.data('rowSlider', RowSlider)
Alpine.data('productSlider', ProductSlider)
Alpine.data('contentSlider', ContentSlider)
Alpine.data('singleSlider', SingleSlider)
Alpine.data('slider', Slider)
Alpine.data('wineFinder', WineFinder)
Alpine.data('modal', Modal)

Alpine.start()

document.addEventListener('DOMContentLoaded', () => {
  replaceTextInDocument([
    { search: /J\. Lohr/g, replace: 'J.\u00A0Lohr' },
    { search: /Gesture|GESTURE/g, replace: '<em>%s</em>' },
    { search: /Touching Lives|TOUCHING LIVES/g, replace: '<em>%s</em>' },
  ])
})

window.addEventListener('load', () => {
  fitText()
})
window.addEventListener('resize', () => {
  debounceFitText(250)
})

window.pushDataLayer = function (data: string) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push(data)
}
