import type { AlpineComponent } from 'alpinejs'
import SlimSelect from 'slim-select'
import '@css/wine-finder/slim-select.css'
import { defineComponent } from '../utils'
import { DataArray, Option } from 'slim-select/dist/store'

interface WineFinderOptions {
  options: string
  preselected?: string
}

interface InfoTableOption {
  data: {
    vipTitle: string
    vipCategory: string
  }
  text: string
  nameOverride: string
  show: string
  vipTitle: string
  vipCategory: string
}

interface InfoTable extends DataArray {
  label: string
  options: InfoTableOption[]
}

interface Location {
  dba: string
  street: string
  city: string
  state: string
  zip: string
  phone: string
  phoneFormatted: string
  storeType: string
  distance: string
  lat: string
  long: string
  otherBrands: {
    otherBrand: string | string[]
  }
  formattedBrands: string[] | undefined
}

type VipGeoLocation = {
  minLatitude: string
  maxLatitude: string
  minLongitude: string
  maxLongitude: string
  originLatitude: string
  originLongitude: string
}

let map: google.maps.Map | undefined = undefined
let infoWindow: google.maps.InfoWindow | undefined = undefined

export const WineFinder = defineComponent(
  ({ options, preselected }: WineFinderOptions) => ({
    selectedOptions: [] as Option[],
    showMap: false,
    storeType: '',
    zipCode: '',
    locations: [] as Location[],
    mapCenter: {
      lat: 37.3326639,
      lng: -121.8918364,
    },
    distance: '10',
    searching: false,
    infoTable: [] as InfoTable | [],
    previousParams: null,
    errorMsg: '',
    markers: [] as google.maps.marker.AdvancedMarkerElement[],
    mapBounds: null as google.maps.LatLngBounds | null,
    currentOpenMarker: null as number | null,

    async init() {
      this.infoTable = JSON.parse(options)

      this.$watch('selectedOptions', () => {
        preselected = undefined
        this.fetchLocations()
      })
      this.$watch('zipCode', () => {
        this.fetchLocations()
      })
      this.$watch('locations', () => {
        this.setMarkers()
      })

      this.$nextTick(() => {
        // check if select element exists
        if (this.$refs.wineSelect || this.$refs.wineSelectTwo) {
          let select1 = new SlimSelect({
            select: this.$refs.wineSelect,
            data: this.infoTable,
            events: {
              afterChange: (val) => {
                this.selectedOptions = val
                select2.setSelected(val.map((option) => option.text))
              },
            },
          })

          let select2 = new SlimSelect({
            select: this.$refs.wineSelectTwo,
            data: this.infoTable || null,
            events: {
              afterChange: (val) => {
                select1.setSelected(val.map((option) => option.text))
              },
            },
          })

          if (preselected) {
            select1.setSelected(JSON.parse(preselected))
          }
        }
      })
    },

    async initMap() {
      if (this.$refs.map) {
        const { Map } = (await google.maps.importLibrary(
          'maps',
        )) as google.maps.MapsLibrary
        const { InfoWindow } = (await google.maps.importLibrary(
          'maps',
        )) as google.maps.MapsLibrary

        map = new Map(this.$refs.map, {
          center: this.mapCenter,
          zoom: 8,
          mapId: '7fb24d622f9932b1',
        })

        // Create an info window to share between markers.
        infoWindow = new InfoWindow()
      }
    },

    async fetchLocations() {
      this.errorMsg = ''

      let brand = this.filterOptions('brands').join()

      // preselected could be a string 'null'
      if (preselected && preselected.length > 0 && preselected !== 'null') {
        brand = JSON.parse(preselected)
      }

      if (!this.zipCode) {
        this.errorMsg = 'Zip code is required.'
        return
      }

      if (!map) {
        this.initMap()
      }
      this.showMap = true

      let params = {
        zip: null as string | null,
        lat: null as number | null,
        long: null as number | null,
        miles: this.distance,
        storeType: this.storeType,
        pagesize: 100,
        brand: brand,
        category4: this.filterOptions('category4').join(), // varietals
      }

      if (!parseInt(this.zipCode)) {
        let latLng = await this.geocodeAddress(this.zipCode)
        if (latLng) {
          params.lat = latLng.lat
          params.long = latLng.lng
        }
      } else {
        params.zip = this.zipCode
      }

      this.searching = true

      let response = await fetch('/actions/_wine-finder/finder', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(params),
      })

      if (!response.ok) {
        this.errorMsg = 'There was an error finding any locations.'
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      let jsonData = await response.json()
      let locationData = []

      if (jsonData.locations) {
        const { location } = jsonData.locations

        // can return a single location or an array
        if (Array.isArray(location)) {
          locationData = location
        } else if (location) {
          locationData = [location]
        }
      } else {
        this.errorMsg = 'There was an error finding any locations.'
      }

      this.setMapBounds(jsonData.geoLocation)

      this.searching = false
      this.locations = this.formatBrands(locationData)
      // this.previousParams = params

      // if (!deepEqual(params, this.previousParams) && this.zipCode != '') {
      //   this.pushDataLayer()
      //   this.previousParams = params
      // }
    },

    formatBrands(locations: Location[]) {
      let infoMap = new Map()
      this.infoTable.forEach((group: any) => {
        group.options.forEach((option: InfoTableOption) => {
          infoMap.set(option.vipTitle, option.nameOverride)
        })
      })

      if (infoMap.size == 0) {
        return locations
      }

      return locations.map((location) => {
        if (Array.isArray(location.otherBrands.otherBrand)) {
          location.formattedBrands = location.otherBrands.otherBrand.map(
            (brand) => {
              // `infoMap.get(brand) || brand` would work as well but
              // brands with show set to false will not be in infoTable
              return infoMap.get(brand) || false
            },
          )
        } else {
          let brand = infoMap.get(location.otherBrands.otherBrand) || false
          if (brand) location.formattedBrands = [brand]
        }

        return location
      })
    },

    filterOptions(category: string) {
      const filteredArray = this.selectedOptions.filter(
        (option) => option.data?.vipCategory == category,
      )

      return filteredArray.map((item) => item.data?.vipTitle)
    },

    geocodeAddress(address: string): Promise<google.maps.LatLngLiteral> {
      let geocoder = new google.maps.Geocoder()

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, (results, status) => {
          if (
            status === google.maps.GeocoderStatus.OK &&
            results &&
            results.length > 0
          ) {
            const location = results[0].geometry.location
            resolve({ lat: location.lat(), lng: location.lng() })
          } else {
            reject(new Error(`Geocode was not successful.`))
          }
        })
      })
    },

    clearMarkers() {
      this.markers.forEach((marker) => {
        marker.map = null
      })
      this.markers = []
    },

    createMarkerContent(location: Location) {
      let content = document.createElement('div')
      content.innerHTML = `
        <div class="pr-2 pb-2">
          <p class="mb-1 text-base font-bold">${location.dba}</p>
          <p class="mb-0 text-tan-dark text-sm">${location.street}, ${location.city} ${location.state}</p>
          <p class="mb-2 font-sans text-tan-dark text-sm">${location.phoneFormatted}</p>
          <p class="mb-0 font-sans">
            <a class="text-sm uppercase text-gold-dark hover:text-gold-darkest" href="https://maps.google.com/maps?hl=en&z=14&q=${location.dba}%21%2C+${location.street}%2C${location.city}%2C+${location.state}" target="_blank">
              Get Directions
            </a>
          </p>
        </div>
      `

      return content
    },

    async setMarkers() {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker',
      )) as google.maps.MarkerLibrary

      // Clear all markers from the map.
      this.clearMarkers()

      this.locations.forEach((location, idx) => {
        const marker = new AdvancedMarkerElement({
          map: map,
          title: location.dba,
          position: {
            lat: parseFloat(location.lat),
            lng: parseFloat(location.long),
          },
          gmpClickable: true,
        })
        const content = this.createMarkerContent(location)

        marker.addListener('click', () => {
          if (infoWindow) {
            infoWindow.close()
            infoWindow.setContent(content)
            infoWindow.open(marker.map, marker)
            infoWindow.setValues({
              jlindex: idx,
            })
            map?.panTo(marker.position)
            this.currentOpenMarker = idx
          }
        })

        /**
         * Push the marker to an array on the
         * component for reference later
         */
        this.markers.push(marker)
      })
    },

    toggleInfoWindow(idx: number) {
      let marker = this.markers[idx]
      let location = this.locations[idx]

      if (marker && infoWindow && location) {
        const content = this.createMarkerContent(location)

        infoWindow.close()
        this.currentOpenMarker = null

        if (infoWindow.get('jlindex') != idx) {
          infoWindow.setContent(content)
          infoWindow.setValues({
            jlindex: idx,
          })
          infoWindow.open(marker.map, marker)
          map?.panTo(marker.position)
          this.currentOpenMarker = idx
        }
      }

      //   this.pushDataLayer(marker)
    },

    setMapBounds(geoLocation: VipGeoLocation) {
      const bounds = {
        north: parseFloat(geoLocation.maxLatitude),
        south: parseFloat(geoLocation.minLatitude),
        east: parseFloat(geoLocation.maxLongitude),
        west: parseFloat(geoLocation.minLongitude),
      }

      map?.fitBounds(bounds)
    },

    discard() {},
  }),
)
