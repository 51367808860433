import fetchJsonp from 'fetch-jsonp'
import serialize from 'form-serialize'
import Cookies from 'js-cookie'
import { SHOP_URL } from '@js/Constants.ts'

// Example Use:
// <div x-data="addtocart('SKU')" x-bind="submit" class=""></div>

export default (sku = null, btnText) => ({
  form: '',
  sku: sku,
  shopDomain: SHOP_URL,
  selectedId: null,

  select(id, from) {
    this.selectedId = id
  },

  isSelected(id) {
    return this.selectedId === id
  },

  whichChild(el, parent) {
    return Array.from(parent.children).indexOf(el) + 1
  },

  submit: {
    ['@submit.prevent.stop'](ev) {
      this.processAddToCart(ev)
    },
  },

  async init() {
    if (!this.sku) {
      return false
    }

    const url = new URL(`${this.shopDomain}/index.cfm`)
    url.searchParams.append('method', 'remote.addToCartForm')
    url.searchParams.append('productSKU', this.sku)

    await fetchJsonp(url.href, {
      jsonpCallback: '?callback',
      timeout: 7000,
    })
      .then((response) => response.json())
      .then((html) => {
        if (btnText) {
          html = html.replace(/Add To Cart/g, btnText)
        }
        this.form = html
      })
    // .catch((error) => {
    //   console.log(error)
    // })

    this.$el.innerHTML = this.form
  },

  processAddToCart(ev) {
    const form = ev.target
    const formAction = form.action.split('?method=')[1]

    let serializedForm = serialize(form, { empty: true, hash: false })
    let d = new Date()

    const url = new URL(`${this.shopDomain}/index.cfm`)
    url.searchParams.append('method', formAction)
    url.searchParams.append('modalLayout', 1)
    url.searchParams.append('timeStamp', d.getTime())
    url.searchParams.append('remoteOrderID', this.getOrderID())
    url.searchParams.append('thirdPartyCookiesSupported', false)

    fetchJsonp(`${url.href}&${serializedForm}`, {
      jsonpCallback: '?callback',
    })
      .then((response) => response.json())
      .then((resp) => {
        // Wine direct can return json or html, depending on the thirdPartyCookiesSupported param
        try {
          const json = JSON.parse(resp)
          this.$dispatch('cart-item-added', json)
        } catch (err) {
          // This "error" is really just that resp is not JSON but html
          this.$dispatch('cart-item-added', {
            content: resp,
          })
        }
      })
    // .catch((error) => {
    //   console.log(error)
    // })

    this.recordAddToCart(serializedForm)
  },

  async recordAddToCart(serializedForm) {
    try {
      const resp = await fetch('/actions/ecommerce-analytics/wine-direct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          data: serializedForm,
        }),
      })

      if (!resp.ok) {
        return
      }

      const json = await resp.json()

      if (typeof window.dataLayer !== 'undefined' && json?.itemDetails?.id) {
        const product = json.itemDetails
        const event = json.action === 'add' ? 'addToCart' : 'removeFromCart'
        let payload = {
          event: event,
          currencyCode: 'USD',
          ecommerce: {
            [json.action]: {
              products: [product],
            },
          },
        }

        window.dataLayer.push(payload)
      }
    } catch (error) {
      // console.error(error.message)
    }
  },

  getOrderID() {
    if (Cookies.get('ORDERID')) {
      return Cookies.get('ORDERID')
    }

    return ''
    // This will look for the order ID in the url
    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop),
    // });
    // return params.remoteOrderID;
  },
})
