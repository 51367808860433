import type { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Pagination, Navigation, A11y, Mousewheel } from 'swiper/modules'
import 'swiper/css'

export function ContentSlider(): AlpineComponent<{
  swiper: Swiper | undefined
  init(): void
  discard(): void
}> {
  return {
    swiper: undefined,

    init() {
      this.swiper = new Swiper(this.$refs.slider, {
        modules: [Pagination, Navigation, A11y, Mousewheel],
        slidesPerView: 1.25,
        breakpoints: {
          720: {
            slidesPerView: 2.25,
          },
          2000: {
            slidesPerView: 3.25,
          },
          2400: {
            slidesPerView: 4.25,
          },
        },
        slidesOffsetBefore: 32,
        slidesOffsetAfter: 32,
        spaceBetween: 24,
        grabCursor: true,
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
          disabledClass: 'opacity-50 cursor-not-allowed',
        },
        pagination: {
          el: this.$refs.pagination,
          type: 'progressbar',
          clickable: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
      })
    },

    discard() {
      this.swiper && this.swiper.destroy()
    },
  }
}
