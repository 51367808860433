import fetchJsonp from 'fetch-jsonp'
import Cookies from 'js-cookie'
import { SHOP_URL } from '@js/Constants.ts'
import Alpine from 'alpinejs'

// Example Use: <div aria-live="polite" x-data="wdcart()" @cart-item-added.window="addItemToCart"></div>

export default function () {
  return {
    html: '',
    shopDomain: SHOP_URL,
    orderId: null,
    teleportTo: 'body',
    cartOpen: false,
    bottles: 0,
    returnFocusEl: null,

    async init() {
      const orderId = this.getOrderID()
      const url = new URL(`${this.shopDomain}/index.cfm`)
      url.searchParams.append('method', 'remote.modalCart')
      url.searchParams.append('dontShowIfZero', 0)
      url.searchParams.append('remoteOrderID', orderId)
      url.searchParams.append('thirdPartyCookiesSupported', 0)

      await fetchJsonp(url.href, {
        jsonpCallback: '?callback',
      })
        .then((response) => response.json())
        .then((resp) => {
          // Wine direct can return json or html, depending on the thirdPartyCookiesSupported param
          try {
            const json = JSON.parse(resp)
            this.html = json.content
            this.orderId = json.orderID
          } catch (err) {
            // This "error" is really just that resp is not JSON but html
            this.html = resp
          }
        })

      this.$el.innerHTML = this.html

      this.updateBottleCount()

      // attach vin65remote to window element
      // vin65 methods are mirrored in this instance
      window.vin65remote = window.vin65remote || {}
      window.vin65remote.cart = this

      // Watch orderID and set the cookie when it cahnges
      this.$watch('orderId', () => {
        Cookies.set('ORDERID', this.orderID, { expires: 7 })
      })
    },

    // Clone cart dropdown and attach to body to get around any scrolling or z-index issues
    teleport(el) {
      let target = document.querySelector(this.teleportTo)

      if (!target)
        warn(`Cannot find x-teleport element for selector: "${expression}"`)

      let clone = el.cloneNode(true)
      clone.classList.add('show_cart')

      let message = clone.querySelector('.v65-widgetModalCart-itemMessage')
      if (message) {
        message.style.display = 'block'
        setTimeout(() => {
          message.style.display = 'none'
          message.remove()
        }, 5000)
      }
      // remove itemMessage from element that gets cloned, otherwise it shows up again
      el.querySelector('.v65-widgetModalCart-itemMessage')?.remove()

      let oldEl = document.querySelector(
        `${this.teleportTo} > .v65-widgetModalCart-dropdown`,
      )

      if (oldEl) {
        oldEl.replaceWith(clone)
      } else {
        target.appendChild(clone)
      }
    },

    toggleCart() {
      this.returnFocusEl = this.$el.querySelector(
        '.v65-widgetModalCart-status a',
      )
      this.cartOpen ? this.hideCart() : this.showCart()
    },

    showCart() {
      let el = this.$el.querySelector('.v65-widgetModalCart-dropdown')
      // this.addOrderIdToLinks()
      this.teleport(el)
      el.removeAttribute('inert')
      this.cartOpen = true
      this.focusCloseBtn()
    },

    focusCloseBtn() {
      let btn = this.$el.querySelector('.v65-widgetModalCart-closeButton a')
      btn && btn.focus()
    },

    hideCart() {
      // Hide all instances just incase more than 1 was shown
      let els = document.querySelectorAll('.v65-widgetModalCart-dropdown')
      els.forEach((el) => {
        el.classList.remove('show_cart')
        el.setAttribute('inert', '')
      })
      this.cartOpen = false

      // return focus to original element
      if (this.returnFocusEl) {
        this.returnFocusEl.focus()
        this.returnFocusEl = null
      }
    },

    // Listener for event triggered from add to cart buttons
    addItemToCart(event) {
      if (!event) return

      // Find the button that triggered the event
      const btn = event?.target.querySelector('button')
      if (btn) {
        this.returnFocusEl = btn
      }

      let el = document.querySelector('.v65-widgetModalCart')
      if (!el) {
        console.log(
          'Cart not found, possibly missing "v65-widgetModalCart" class on cart element.',
        )
        return
      }

      el.innerHTML = event.detail.content

      if (event.detail.orderID) {
        event.detail.orderID !== this.orderId && console.log('orderId changed')

        this.orderId = event.detail.orderID
      }

      this.updateBottleCount()

      this.showCart()
    },

    updateBottleCount() {
      Alpine.store(
        'cartCount',
        parseInt(
          document.querySelector('.v65-widgetModalCart-itemCount').innerHTML,
        ),
      )

      this.$dispatch('cart-count-updated')
    },

    getOrderID() {
      if (Cookies.get('ORDERID')) {
        return Cookies.get('ORDERID')
      }

      return ''
      // This will look for the order ID in the url
      // const params = new Proxy(new URLSearchParams(window.location.search), {
      //   get: (searchParams, prop) => searchParams.get(prop),
      // });
      // return params.remoteOrderID;
    },
  }
}
